@import "reset-css";

.App {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 5%;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

nav {
  background: rgb(240, 240, 240);
  width: 100%;
  margin-bottom: 5%;
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 1% 0;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }

  a:active {
    text-decoration: underline;
  }
}

.home {
  display: flex;
  flex-direction: column;
}

.screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

._media-recorder {
  display: flex;
  flex-direction: column;
}

._qr-gen {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.3em;
  }

  input {
    margin-bottom: 2em;
  }
}

._qrcode {
  margin-bottom: 2em;
}

._qr-scanner {
  // border: 2px red solid;
  display: flex;
  flex-direction: column;
  width: 20vw;
}
